<template>
  <b-card-actions
    action-collapse
    ref="refreshCard"
    title="Daftar Pencapaian"
    action-refresh
    @refresh="refreshStop('refreshCard')"
  >
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-button
          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
          v-b-modal.modal-tambah
          variant="primary"
        >
          <feather-icon icon="PlusIcon" class="mr-50" />
          {{ $t('Add') }}
        </b-button>
        <b-modal
          id="modal-tambah"
          size="xl"
          ok-title="Tutup"
          ok-variant="secondary"
          ok-only
          centered
          title="Form Add"
        >
          <!-- 
            cancel-variant="secondary"
            cancel-title="Tutup"-->
          <validation-observer ref="simpleRules">
            <div class="d-flex">
              <feather-icon icon="UserIcon" size="19" />
              <h6 class="mb-0 ml-50">Data Pencapaian</h6>
            </div>
            <b-form>
              <b-row>
                <!-- Tahun -->
                <b-col cols="4">
                  <b-form-group label="Tahun" label-for="v-tahun">
                    <validation-provider
                      #default="{ errors }"
                      name="tahun"
                      rules="required"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="tahun"
                        :options="opttahun"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Bulan -->
                <b-col cols="4">
                  <b-form-group label="Bulan" label-for="v-bulan">
                    <validation-provider
                      #default="{ errors }"
                      name="bulan"
                      rules="required"
                    >
                      <b-form-select
                        :state="errors.length > 0 ? false : null"
                        v-model="bulan"
                        :options="optbulan"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <!-- Pencapaian -->
                <b-col cols="4">
                  <b-form-group label="Pencapaian" label-for="v-pencapaian">
                    <validation-provider
                      #default="{ errors }"
                      name="pencapaian"
                      rules="required|min:9"
                    >
                      <b-form-input
                        :state="errors.length > 0 ? false : null"
                        v-model="pencapaian"
                        id="v-pencapaian"
                        placeholder="Pencapaian"
                        type="number"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <b-form-group label="Dari Tanggal" label-for="v-start_date">
                    <validation-provider #default="{ errors }" name="Dari Tanggal">
                      <b-form-datepicker
                        id="start_date"
                        v-model="start_date"
                        class="mb-1"
                      />
                      <p>start_date: '{{ start_date }}'</p>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="4">
                  <b-form-group label="Sampai Tanggal" label-for="v-end_date">
                    <validation-provider #default="{ errors }" name="Sampai Tanggal">
                      <b-form-datepicker id="end_date" v-model="end_date" class="mb-1" />
                      <p>end_date: '{{ end_date }}'</p>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col cols="12">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    @click.prevent="validationForm"
                    variant="primary"
                    class="mr-1"
                  >
                    Add
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    type="reset"
                    variant="outline-secondary"
                  >
                    Reset
                  </b-button>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-modal>
      </b-col>
    </b-row>
    <b-row>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>
      <b-col md="4" sm="8" class="my-1">
        <b-form-group
          :label="$t('Sort')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="sortBySelect"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-select
              id="sortBySelect"
              v-model="sortBy"
              :options="sortOptions"
              class="w-75"
            >
              <template v-slot:first>
                <option value="">-- none --</option>
              </template>
            </b-form-select>
            <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy" class="w-25">
              <option :value="false">Asc</option>
              <option :value="true">Desc</option>
            </b-form-select>
          </b-input-group>
        </b-form-group>
      </b-col>
      <b-col md="6" class="my-1">
        <b-form-group
          :label="$t('Filter')"
          label-cols-sm="3"
          label-align-sm="right"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">{{$t('Clear')}}</b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          small
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="items"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          @filtered="onFiltered"
        >
          <template #cell(actions)="row">
            <b-button
              title="'Detail'"
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
              variant="outline-secondary"
            >
              <feather-icon icon="ListIcon" />
            </b-button>
            <b-button size="sm" @click="row.toggleDetails" class="mr-1">
              {{ row.detailsShowing ? "Hide" : "Show" }} Details 2
            </b-button>
            <b-button
              title="'Ubah'"
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
              variant="outline-info"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              title="'Hapus'"
              size="sm"
              @click="info(row.item, row.index, $event.target)"
              class="mr-1"
              variant="outline-danger"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </template>

          <template #row-details="row">
            <b-card>
              <ul>
                <li v-for="(value, key) in row.item" :key="key">
                  {{ key }}: {{ value }}
                </li>
              </ul>
            </b-card>
          </template>
        </b-table>
      </b-col>
      <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
        <pre>{{ infoModal.content }}</pre>
      </b-modal>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="center"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>
  </b-card-actions>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email, confirmed, password } from "@validations";
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import {
  BTable,
  BAvatar,
  BBadge,
  BModal,
  BRow,
  BCol,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
  BCardText,
  BFormDatepicker,
  VBTooltip,
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BCardActions,
    BModal,
    BTable,
    BAvatar,
    BBadge,
    BRow,
    BCol,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BFormTextarea,
    BCardText,
    BFormDatepicker,
    VBTooltip,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      required,
      password,
      email,
      confirmed,
      tahun: "",
      bulan: null,
      pencapaian: null,
      start_date: null,
      end_date: null,
      opttahun: [
        { value: 2021, text: 2021 },
        { value: 2022, text: 2022 },
        { value: 2023, text: 2023 },
        { value: 2024, text: 2024 },
        { value: 2025, text: 2025 },
        { value: 2026, text: 2026 },
        { value: 2027, text: 2027 },
        { value: 2028, text: 2028 },
      ],
      optbulan: [
        { value: 1, text: "Januari" },
        { value: 2, text: "Februari" },
        { value: 3, text: "Maret" },
        { value: 4, text: "April" },
        { value: 5, text: "Mei" },
        { value: 6, text: "Juni" },
        { value: 7, text: "Juli" },
        { value: 8, text: "Agustus" },
        { value: 9, text: "September" },
        { value: 10, text: "Oktober" },
        { value: 11, text: "November" },
        { value: 12, text: "Desember" },
      ],
      optjk: [
        { value: "L", text: "Laki-laki" },
        { value: "P", text: "Perempuan" },
      ],
      perPage: 10,
      pageOptions: [10, 20, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      fields: [
        {
          key: "id",
          sortable: true,
          label: "Id",
        },
        {
          key: "tahun",
          label: "tahun",
        },
        { key: "bulan", label: "Periode", sortable: false },
        "pencapaian",
        // "city",
        // "start_date",
        "start_date",
        // "age",
        "end_date",
        // { key: "status", label: "Status", sortable: true },
        { key: "actions", label: "Aksi" },
      ],
      items: [
        {
          id: 1,
          tahun: "2022",
          bulan: "Januari",
          pencapaian: "100000000",
          start_date: "01/01/2022",
          end_date: "31/01/2022",
        },
        {
          id: 2,
          // eslint-disable-next-line global-require
          tahun: "2022",
          bulan: "Februari",
          pencapaian: "105000000",
          start_date: "02/02/2022",
          end_date: "28/02/2022",
        },
        {
          id: 3,
          tahun: "2022",
          bulan: "Maret",
          pencapaian: "100000000",
          start_date: "03/03/2022",
          end_date: "31/03/2022",
        },
        {
          id: 4,
          tahun: "2022",
          bulan: "April",
          pencapaian: "100000000",
          start_date: "04/04/2022",
          end_date: "30/04/2022",
        },
        {
          id: 5,
          tahun: "2022",
          bulan: "Mei",
          pencapaian: "100000000",
          start_date: "05/05/2022",
          end_date: "31/05/2022",
        },
        {
          id: 6,
          tahun: "2022",
          bulan: "Juni",
          pencapaian: "100000000",
          start_date: "06/06/2022",
          end_date: "30/06/2022",
        },
        {
          id: 7,
          tahun: "2022",
          bulan: "Juli",
          pencapaian: "100000000",
          start_date: "07/07/2022",
          end_date: "31/07/2022",
        },
        {
          id: 8,
          tahun: "2022",
          bulan: "Agustus",
          pencapaian: "100000000",
          start_date: "08/08/2022",
          end_date: "31/08/2022",
        },
        {
          id: 9,
          tahun: "2022",
          bulan: "September",
          pencapaian: "100000000",
          start_date: "09/09/2022",
          end_date: "30/09/2022",
        },
        {
          id: 10,
          tahun: "2022",
          bulan: "Oktober",
          pencapaian: "100000000",
          start_date: "10/10/2022",
          end_date: "31/10/2022",
        },
      ],
      status: [
        {
          1: "Current",
          2: "Professional",
          3: "Rejected",
          4: "Resigned",
          5: "Applied",
        },
        {
          1: "light-primary",
          2: "light-success",
          3: "light-danger",
          4: "light-warning",
          5: "light-info",
        },
      ],
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    validationForm() {
      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          // eslint-disable-next-line
          alert("form submitted!");
        }
      });
    },
  },
};
</script>
